
import { back_url, authorization } from './constants'

import axios from 'axios'
// import './App.css';
import React from 'react'
import { IoCheckmarkCircle } from "react-icons/io5";
import { BiSolidErrorCircle } from "react-icons/bi";
import { MdPending } from "react-icons/md";
import { TbTrashOff } from "react-icons/tb"

const DisplayStatus = (props) => {
  switch(props.status) {
    case "success": return <IoCheckmarkCircle alt="Success" style={{color:"green"}}/>
    case "error": return <BiSolidErrorCircle alt="Error" style={{color:"red"}}/>
    case "pending": return <MdPending alt="Pending" style={{color:"blue"}}/>
    default: return <div>{props.status}</div>
  }
}

axios.defaults.headers.common['Authorization'] = authorization

export default class Task extends React.Component {
  constructor(props) {
    super(props)

    // Initializing the state 
    this.state = {
      task: props.task
    }
  }

  // refresh() {
  //   this.setState({ refreshPending: true })
  //   axios.put(back_url + '/api/refresh/home', { id: this.state.home._id })
  //     .then(response => {
  //       this.setState({refreshPending: false }, this.props.updateList())
  //     })
  //     .catch(error => {
  //       console.error(error);
  //     })
  // }

  update(newElements) {
    const newTask = {
      ...this.state.task,
      ...newElements
    }
    axios.put(back_url + '/api/task', { task: newTask })
      .then(response => {
        this.setState({ task: { ...response.data } })
      })
      .catch(error => {
        console.error(error);
      })
  }

  render() {
    return (
    <div>
      <DisplayStatus status = {this.state.task.status}/>
      <TbTrashOff className="icon-button" onClick = {() => this.props.delete(this.state.task._id)}/>
    <div>type: {this.state.task.type}</div>
    <div>url:  <a href={this.state.task.url} rel="noreferrer" target="_blank">{this.state.task.url}</a></div>
    <div>createdDate: {this.state.task.createdDate}</div>
    <div>updatedDate: {this.state.task.updatedDate}</div>
    <div>error:  {this.state.task.error}</div>
    {this.state.task.status !== "pending" &&  <button onClick={() => this.update({status: "pending"})}>Reinitialiser</button>}
    <hr/>
    </div>
    )
  }
}

Task.propTypes = {
  //task: ,//PropTypes.string.isRequired
  // delete: 
}
