import './App.css';

import React from 'react'
import axios from 'axios'

import HomeList from './HomeList'
import TaskList from './TaskList'
import {back_url, authorization} from "./constants"

import { FaServer } from "react-icons/fa"


axios.defaults.headers.common['Authorization'] = authorization

class App extends React.Component{
  constructor(props) {
    super(props)

    // Initializing the state 
    this.state = {
      token: null,
      displayHome: localStorage.getItem("displayHome") !== "false",
      serverStatusColor: "orange",
    }
  }

  toggleDisplay() {
    localStorage.setItem("displayHome", !this.state.displayHome)
    this.setState({displayHome: !this.state.displayHome})
  }


  testServer() {
    axios.get(back_url + '/api/health/').then(response => {
      this.setState({serverStatusColor: "green"})
    }).catch(err => {
      this.setState({serverStatusColor: "red"})
    })
  }

  componentDidMount() {
    this.testServer()
    this.interval = setInterval(() => this.testServer(), 5000)
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <div>
        <div style={{float: "right"}}>
        <FaServer style={{color:this.state.serverStatusColor}} onClick={() => this.toggleDisplay()}/>
        </div>
        
        {this.state.displayHome ? <HomeList /> : <TaskList /> }
      </div>
    );
  }
}

export default App;
